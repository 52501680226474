import { isUndefined } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	FirstLine,
	SecondLine,
	TitleWrapper,
	Wrapper,
} from '../../CommonComponents'

import AxialLengthGraphBino from './AxialLengthGraphBino'
import AxialLengthGraphMono from './AxialLengthGraphMono'
import { CalculatedAxialLength, Scale } from '../interfaces'
import { calculateYScale } from '../utils'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectExamData } from '../../../../features/exam/selectors'

type Props = {
	calculatedAxialLength: CalculatedAxialLength
}

const AxialLengthGraphRighLeft: React.FC<Props> = ({
	calculatedAxialLength,
}) => {
	const { t } = useTranslation()
	const [scaleY, setScaleY] = useState<Scale>()
	const [displayRight, setDisplayRight] = useState<boolean>(false)
	const [displayLeft, setDisplayLeft] = useState<boolean>(false)
	const [displayBoth, setDisplayBoth] = useState<boolean>(false)
	const [eyeTitle, setEyeTitle] = useState<string>('')
	const exam = useSelector(selectExamData)
	const gender = exam?.dif.gender
	const ethnicity = exam?.dif.ethnicity

	useEffect(() => {
		if (!isUndefined(calculatedAxialLength)) {
			setScaleY(
				calculateYScale(
					calculatedAxialLength?.left.values,
					calculatedAxialLength?.right.values,
					21,
					26,
				),
			)
			setDisplayRight(calculatedAxialLength.eye === 'R')
			setDisplayLeft(calculatedAxialLength.eye === 'L')
			setDisplayBoth(calculatedAxialLength.eye === 'B')
		}
	}, [calculatedAxialLength])

	useEffect(() => {
		setEyeTitle(
			`${t('content.myopia.axialLengthAnalysis.title')}${
				displayRight ? ' OD' : displayLeft ? ' OS' : ''
			}`,
		)
	}, [displayRight, displayLeft, t])

	return (
		<Wrapper>
			<TitleWrapper>
				<FirstLine>{t('content.myopia.myopiaCareSolution')}</FirstLine>
				<SecondLine>{eyeTitle}</SecondLine>
			</TitleWrapper>
			<Grid container>
				{displayBoth && (
					<AxialLengthGraphBino
						scaleY={scaleY}
						calculatedAxialLength={calculatedAxialLength}
						gender={gender}
						ethnicity={ethnicity}
					/>
				)}
				{displayRight && (
					<AxialLengthGraphMono
						scaleY={scaleY}
						calculatedAxialLengthData={calculatedAxialLength.right}
						gender={gender}
						ethnicity={ethnicity}
					/>
				)}
				{displayLeft && (
					<AxialLengthGraphMono
						scaleY={scaleY}
						calculatedAxialLengthData={calculatedAxialLength.left}
						gender={gender}
						ethnicity={ethnicity}
					/>
				)}
			</Grid>
		</Wrapper>
	)
}

export default AxialLengthGraphRighLeft
