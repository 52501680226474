import { isValidDateFormat } from '../../libs/time'
import { RootState } from '../../model/model'

export const selectExam = (state: RootState) => state.exam

export const selectExamDoctor = (state: RootState) => state.exam.doctor

export const selectExamData = (state: RootState) => state.exam.exam

export const selectExamId = (state: RootState) => state.exam.exam?._id

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
export const selectDateFormat = (state: RootState) => {
	const storeDateFormat = state.exam.exam?.store?.dateFormat
	const isValidStoreDateFormat = isValidDateFormat(storeDateFormat)
	if (!isValidStoreDateFormat) {
		console.warn('>>> received an invalid date format from server', {
			received: storeDateFormat,
			defaultingTo: DEFAULT_DATE_FORMAT,
		})
	}
	return isValidStoreDateFormat ? storeDateFormat! : DEFAULT_DATE_FORMAT
}
