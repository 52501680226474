import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { selectAppRoom, selectUsername } from '../features/auth/selectors'
import coreMediaActions from '../features/coremedia/actions'
import examActions from '../features/exam/actions'
import { selectExam } from '../features/exam/selectors'
import useScreenshare from './useScreenshare'
import { useSocketExamById, useSocketShare, useSocketRoom } from './useSocket'

export const usePrepareExam = () => {
	const { exam } = useSelector(selectExam)
	const username = useSelector(selectUsername)
	const dispatch = useDispatch()
	const room = useSelector(selectAppRoom)
	const params = useParams<{ examId?: string }>()

	const paramsExamId = useMemo(() => params.examId || '', [params])

	const currentExamId = useMemo(
		() => (room ? room.doctorRoomLockedByExamId || '' : paramsExamId),
		[paramsExamId, room],
	)

	const examLoadedIsOld = useMemo(
		() => exam && exam._id !== currentExamId,
		[exam, currentExamId],
	)
	const examIdInUrlNeedsUpdate = useMemo(
		() => paramsExamId !== currentExamId,
		[paramsExamId, currentExamId],
	)

	const history = useHistory()

	useEffect(() => {
		dispatch(coreMediaActions.fetchCoreMediaContent())
	}, [dispatch])

	useEffect(() => {
		if (!exam || examLoadedIsOld) {
			if (currentExamId && currentExamId.length > 0) {
				examActions.setCockpitTechnicianOnExam({
					examId: currentExamId,
					cockpitTechnician: username,
				})
			}

			// TODO: REMOVE THIS FROM HERE, Duplicates call with SOCKET CALL (NO TIME ATM)
			dispatch(
				examActions.setExamData({
					examId: currentExamId,
					cockpitTechnician: username,
				}),
			)
		}
	}, [dispatch, currentExamId, exam, examLoadedIsOld, username])

	useEffect(() => {
		if (examIdInUrlNeedsUpdate) {
			history.replace(`/${currentExamId}`)
		}
	}, [currentExamId, examIdInUrlNeedsUpdate, history])

	useSocketExamById(exam?._id)

	useSocketShare(exam?._id)

	useScreenshare()

	useSocketRoom()
}
