import {
	getExam,
	getInstrumentDataApi,
	getPatientExams,
	getProfilePhotoApi,
	getStoreApi,
	updateExamApi,
} from '../../apiCalls'
import {
	offRoomExamStatus,
	Exam,
	ExamApi,
	InstrumentsData,
} from '../../model/exam'
import { InstrumentType, instrumentTypes } from '../../model/instruments'
import { AppThunk } from '../../model/model'
import { DoctorInfo } from '../../model/user'
import authActions from '../auth/actions'
import { selectAppRoom } from '../auth/selectors'
import displayContentActions from '../displayContent/actions'
import { slice } from './slice'

const mapInstrumentTypeToKeys: {
	[k in InstrumentType]: keyof InstrumentsData
} = {
	AR: 'autorefraction',
	LM: 'lensmeter',
	KM: 'keratometer',
	NT: 'tonometer',
	PH: 'phoropter',
	VF: 'visualFields',
	SL: 'slitLamp',
	RI: 'retinalImaging',
}

const setCockpitTechnicianOnExam = async ({
	examId,
	cockpitTechnician,
}: {
	examId: string
	cockpitTechnician: string
}) => await updateExamApi(examId, { cockpitTechnician })

const setExamData =
	({
		examId,
		username,
	}: {
		examId?: string
		username?: string
		cockpitTechnician?: string
	}): AppThunk =>
	async dispatch => {
		if (!examId) {
			dispatch(slice.actions.clearExamData())
			dispatch(displayContentActions.clearDisplayContent())
			return
		}
		const exam = await getExam(examId)

		// if an exam is ended or is moved out of the room and an event related to that arrives, setting the exam in the redux store causes the socket to reconnect
		if (exam && offRoomExamStatus.includes(exam.status)) {
			dispatch(slice.actions.clearExamData())
			dispatch(displayContentActions.clearDisplayContent())
			return
		}

		const patientId = exam?.internalPatient?._id
		const patientExams = await getPatientExams(patientId)
		const numberOfVisits = (patientExams as Exam[]).length.toString()

		const doctorUsername: string = username || (exam as ExamApi)?.doctor

		const doctorPhotoData = await getProfilePhotoApi(doctorUsername)
		const doctorPhotoUrl =
			doctorPhotoData && doctorPhotoData.photo
				? doctorPhotoData.photo
				: undefined

		const doctorInfo: DoctorInfo = {
			username: doctorUsername,
			imageUrl: doctorPhotoUrl,
			name: exam?.doctorName,
			surname: exam?.doctorSurname,
		}

		const resultExam = exam as Exam

		for (const itype of instrumentTypes) {
			const response = await getInstrumentDataApi(examId, itype)
			if (response && response.examData) {
				const key = mapInstrumentTypeToKeys[itype]
				resultExam.preTest = {
					...resultExam.preTest,
					instruments: {
						...resultExam.preTest?.instruments,
						[key]: response.examData,
					},
				}
			}
		}

		dispatch(
			slice.actions.setExamData({
				doctor: doctorInfo,
				exam: resultExam,
				numberOfVisits,
			}),
		)
	}

const checkExamInRoom =
	(storeId?: string, cockpitTechnician?: string): AppThunk =>
	async (dispatch, getState) => {
		const state = getState()
		const oldExamId = selectAppRoom(state)?.doctorRoomLockedByExamId

		if (!storeId) return
		const store = await getStoreApi(storeId)
		if (store) {
			dispatch(authActions.replaceStoreData(store))
			const state = getState()
			const newExamId = selectAppRoom(state)?.doctorRoomLockedByExamId
			if (newExamId !== oldExamId) {
				dispatch(displayContentActions.setDisplayContent({}))
				if (newExamId && cockpitTechnician) {
					setCockpitTechnicianOnExam({ examId: newExamId, cockpitTechnician })
				}
				dispatch(setExamData({ examId: newExamId, cockpitTechnician }))
			}
		}
	}

const examActions = {
	...slice.actions,
	setExamData,
	checkExamInRoom,
	setCockpitTechnicianOnExam,
}

export default examActions
