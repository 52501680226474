import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectVideoStatus } from '../../features/displayContent/selectors'
// import uiActions from '../../features/ui/actions'
import styled from 'styled-components'
import {
	FirstLine,
	SecondLine,
	ThirdLine,
	TitleWrapper,
	Wrapper,
} from './CommonComponents'
import { pxToRem, hexToRgba } from '../../libs/style'
import { DisplayableContentType } from '../../model/model'

import theme from '../../styleguide/theme'
import { selectCoreMediaDiseaseAndTreatment } from './../../features/coremedia/selectors'
import { ConnectAddon } from '../../model/coremedia'

const videoTransitionTime = theme.transitions.duration.shortest

const VideoContainer = styled.div<{ fullscreen?: boolean; loaded?: boolean }>`
	${props => (!props.loaded ? 'display: none' : '')};
	max-height: 100%;
	z-index: ${props => (props.fullscreen ? 1 : 'unset')};
	top: ${props => (props.fullscreen ? '0' : 'unset')};
	left: ${props => (props.fullscreen ? '0' : 'unset')};
	position: ${props => (props.fullscreen ? 'fixed' : 'unset')};
	width: ${props => (props.fullscreen ? '100vw' : 'auto')};
	border-radius: ${props => (props.fullscreen ? '0' : `${pxToRem(15)}rem`)};
	padding: ${props =>
		props.fullscreen ? '0' : `${pxToRem(theme.spacing(1.5))}rem`};
	transition: all ${videoTransitionTime}ms ease-in;
	background: linear-gradient(
		to bottom,
		${hexToRgba(theme.palette.grey[200], 0.1)},
		${hexToRgba(theme.palette.secondary.light, 0.1)}
	);
	border: ${props =>
		props.fullscreen
			? '0'
			: `2px solid ${hexToRgba(theme.palette.grey[200], 1)}`};
	backdrop-filter: blur(${pxToRem(15)}rem);
`

const FullScreenVideo = styled.video<{
	fullscreen?: boolean
	isPlaying?: boolean
}>`
	max-width: 100%;
	${props => (props.fullscreen ? 'width: 100%' : '')};
	max-height: ${props => (props.fullscreen ? 'unset' : '100%')};
	${props => (props.fullscreen ? 'height: 100vh' : '')};
	border-radius: ${props => (props.fullscreen ? '0' : `${pxToRem(15)}rem`)};
	background-color: ${props =>
		props.fullscreen ? theme.palette.common.black : 'unset'};
	filter: ${props =>
		props.isPlaying ? 'unset' : props.fullscreen ? 'brightness(35%)' : 'unset'};
	position: relative;
	z-index: ${theme.zIndex.videoFullScreen};
`

const VideoDetail = styled.div`
	background-color: ${theme.palette.common.white};
	z-index: ${theme.zIndex.videoFullScreenDetails};
	border-radius: ${pxToRem(theme.spacing(3))}rem;
	margin: ${pxToRem(theme.spacing(3))}rem;
	margin-top: 0;
	top: 10vh;
	overflow: hidden;
	position: fixed;
	left: 0;
`

const VideoTitleWrapper = styled.div`
	padding: ${pxToRem(theme.spacing(2))}rem;
`

const VideoFirstLine = styled.div`
	color: ${theme.palette.background.secondary};
	font-size: ${pxToRem(14)}rem;
	width: 100%;
`

const VideoSecondLine = styled.div`
	font-family: 'Ivar Display';
	font-style: italic;
	font-size: ${pxToRem(26)}rem;
	color: ${theme.palette.background.secondary};
	width: 100%;
	white-space: nowrap;
`

const VideoWrapper: React.FC<{
	asset: ConnectAddon
	diseaseTitle: string
}> = ({ asset, diseaseTitle }) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const videoStatus = useSelector(selectVideoStatus)
	const videoRef = useRef<HTMLVideoElement>(null)
	const playing = videoStatus?.playing
	const startTime = videoStatus?.startTime
	const endTime = videoStatus?.endTime
	const isFullscreen = videoStatus?.isFullscreen
	const currentTime = videoStatus?.currentTime
	const assetSrc = asset.media.videos[0]
	useEffect(() => {
		if (!videoRef.current) {
			return
		}
		if (currentTime !== undefined) {
			videoRef.current.currentTime = currentTime
		}
		if (playing) {
			videoRef.current.play()
		} else {
			videoRef.current.pause()
		}
	}, [playing, currentTime])

	const loopTimeVideo = (currentTime: number) => {
		const video = videoRef.current
		if (!video) {
			return
		}
		const { duration } = video
		const shouldVideoLoop =
			(!!endTime && currentTime > endTime) ||
			(!!startTime && currentTime >= duration)

		if (shouldVideoLoop) {
			resetVideoTimeToStartTime()
		}
	}

	const resetVideoTimeToStartTime = () => {
		const video = videoRef.current!
		video.pause()
		video.currentTime = startTime || 0
		setTimeout(() => {
			video.play()
		}, 500)
	}

	return (
		<VideoContainer fullscreen={isFullscreen} loaded={isLoaded}>
			<VideoDetail data-testid={playing ? 'playing' : 'not-playing'}>
				{isFullscreen && (
					<VideoTitleWrapper data-testid="fullscreen-detail">
						<VideoFirstLine>{diseaseTitle}</VideoFirstLine>
						<VideoSecondLine>{asset.value}</VideoSecondLine>
					</VideoTitleWrapper>
				)}
			</VideoDetail>
			{asset && assetSrc ? (
				<FullScreenVideo
					onTimeUpdate={ev => loopTimeVideo(ev.currentTarget.currentTime)}
					src={assetSrc}
					ref={videoRef}
					fullscreen={isFullscreen}
					isPlaying={playing}
					muted
					onLoadStart={() => {
						setIsLoaded(false)
					}}
					onCanPlay={() => {
						setIsLoaded(true)
					}}
				/>
			) : null}
		</VideoContainer>
	)
}

const FullContent: React.FC<{
	asset: ConnectAddon
	diseaseTitle: string
}> = ({ asset, diseaseTitle }) => {
	return (
		<Wrapper data-testid="disease-treatment-component">
			<TitleWrapper>
				{diseaseTitle && <FirstLine>{diseaseTitle}</FirstLine>}
				{asset && (
					<>
						<SecondLine data-testid="disease-treatment-second-line">
							{asset.value}
						</SecondLine>
						<ThirdLine>{asset.description}</ThirdLine>
					</>
				)}
			</TitleWrapper>
			<VideoWrapper asset={asset} diseaseTitle={diseaseTitle} />
		</Wrapper>
	)
}

const DiseaseTreatment: React.FC<{ type?: DisplayableContentType }> = ({
	type,
}) => {
	const [macro, category, detail] = (type as string)?.split(':')
	// const dispatch = useDispatch()

	const assets = useSelector(selectCoreMediaDiseaseAndTreatment)

	if (macro && category && detail) {
		const asset = assets[category].content[macro].content[detail]
		const diseaseTitle = assets[category].value
		return <FullContent asset={asset} diseaseTitle={diseaseTitle} />
	} else if (macro) {
		const diseaseTitle = assets[macro].value
		return (
			<Wrapper data-testid="disease-treatment-component">
				<TitleWrapper>
					<FirstLine>{diseaseTitle}</FirstLine>
					{/* category of disease and treatment have the same title */}
				</TitleWrapper>
			</Wrapper>
		)
	} else {
		return null
	}
}
export default DiseaseTreatment
