import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { keepAliveUserLegacyApi } from '../apiCalls'
import { selectUsername } from '../features/auth/selectors'
import {
	connectToSocketKeepAlive,
	disconnectFromSocketKeepAlive,
} from '../socket/keepAlive/keepAliveSocket'

const TEN_SECONDS = 1000 * 10

export const useKeepAliveUser = () => {
	const username = useSelector(selectUsername)

	useEffect(() => {
		connectToSocketKeepAlive({
			username,
		})

		return () => disconnectFromSocketKeepAlive()
	}, [username])
}

//NOT MERGE TO 17.0.0, USE useKeepAliveUser
export const useKeepAliveUserLegacy = () => {
	const username = useSelector(selectUsername)

	useEffect(() => {
		const keepAliveUser = () => {
			keepAliveUserLegacyApi(username)
		}

		keepAliveUser()

		const fetchInterval: number | undefined = window.setInterval(
			keepAliveUser,
			TEN_SECONDS,
		)

		return function cleanUp() {
			fetchInterval && clearInterval(fetchInterval)
		}
	}, [username])
}
