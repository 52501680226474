import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExamState } from '../../model/exam'

const initialState: ExamState = {
	doctor: undefined,
	exam: undefined,
	numberOfVisits: '',
}

export const slice = createSlice({
	name: 'exam',
	initialState,
	reducers: {
		setExamData: (state, { payload }: PayloadAction<ExamState>) => {
			return { ...payload }
		},
		clearExamData: () => {
			return {}
		},
	},
})

export default slice.reducer
