import React from 'react'
import styled from 'styled-components'
import theme, { AZURE_PALETTE } from '../../../styleguide/theme'

type StatusIndicatorProps = {
	active: boolean
	error: boolean
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const ContainerStatus = styled.div`
	display: flex;
	flex-direction: row;
`

const LabelContainer = styled.div`
	font-size: 1.8vh;
	&.init {
		color: ${theme.palette.grey[400]};
	}
	&.error {
		color: ${AZURE_PALETTE.RED};
	}
	&.success {
		color: ${AZURE_PALETTE.GREEN};
	}
`

const AzureStatusIndicator: React.FC<StatusIndicatorProps> = ({
	active,
	error,
}) => {

	const status =
		error
			? 'VC INIT FAILED'
			: active
			? 'VC ACTIVE'
			: 'Connecting...'
	const statusClassName =
		error ? 'error' : active ? 'success' : 'init'

	return (
		<Container data-id={'azure-status-indicator'}>
			<ContainerStatus>
				<LabelContainer data-testid={status} className={statusClassName}>
					{status}
				</LabelContainer>
			</ContainerStatus>
		</Container>
	)
}

export default AzureStatusIndicator
