import { AppConfig } from './model/model'

const safeEnv = (key: string, defaultValue?: string): string => {
	const value: string = process.env[key] || ''
	const result: any = value || defaultValue

	// strict check because empty string must be evaluated as true
	if (result === undefined) {
		throw new Error(`Missing key in in .env file: ${key}`)
	}
	return result
}

const appConfig: AppConfig = {
	app: {
		name: safeEnv('REACT_APP_NAME', ''),
		version: safeEnv('REACT_APP_VERSION', ''),
	},
	region: safeEnv('REACT_APP_REGION', 'NA'),
	apiUrl: safeEnv('REACT_APP_API_URL'),
	apiUrlV2: safeEnv('REACT_APP_API_URLV2'),
	mediaUrl: safeEnv('REACT_APP_MEDIA_URL'),
	socketUrl: safeEnv('REACT_APP_SOCKET_URL'),
	environment: safeEnv('REACT_APP_ENVIRONMENT', ''),
	logout: safeEnv(
		'REACT_APP_LOGOUT_URL',
		'https://mytestlogin.luxottica.com/nidp/app/logout',
	),
	login: {
		clientId: safeEnv(
			'REACT_APP_LOGIN_CLIENT_ID',
			'b9ab1456-7475-424d-a6c9-21fda2b6a929',
		),
		url: safeEnv(
			'REACT_APP_LOGIN_URL',
			'https://mydevlogin.luxottica.com/nidp/oauth/nam/authz',
		),
		redirectUri: safeEnv(
			'REACT_APP_LOGIN_REDIRECT_URI',
			'http://localhost:3000/',
		),
	},
	languages: ['en-US', 'sp-US'],
}

export default appConfig
